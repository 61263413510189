import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Button from '../Button'
import DynamicLink from '../DynamicLink'
import Typography from '../Typography'
import { styledText } from '../Typography/Typography.styles'
import { TopicName } from '../Article/Article.styles'

export const ThumbnailLinkContainer = styled.div`
  width: 315px;
  overflow: hidden;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkBlue};
  transition: box-shadow 0.4s;
  position: relative;

  &:hover {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 362px;
  }
`

export const HeroImage = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
  height: 189px;
  border-radius: 8px 8px 0px 0px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 217px;
  }
`

export const ThumbnailContentContainer = styled.div`
  word-break: break-word;
  height: 416px;
  padding: 30px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 470px;
  }
`

export const ThumbnailItemListWrapper = styled.div`
  max-height: 76px;
  overflow: hidden;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-height: 74px;
  }
`

export const ThumbnailItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

// incase TopicName is a link, override it
export const ThumbnailTopicName = styled(TopicName).attrs({
  as: 'div',
})<{ isPmArticle?: boolean }>`
  margin: 0 4px 10px 0;

  &:hover {
    background: ${({ theme }) => theme.colors.hawkesBlue};
  }

  ${({ theme, isPmArticle }) => isPmArticle && `
    background: ${theme.colors.darkBlue} !important;
    color: ${theme.colors.white};
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 0 10px 12px 0;
  }
`

export const Title = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Heading 3'],
})`
  margin-top: 0px;
  margin-bottom: 10px;
  word-break: break-word;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 2']]}
    margin-bottom: 14px;
  }
`

export const SubCopy = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  margin-bottom: 14px;
`

export const CreatedDate = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  margin: 0 0 10px;
  color: ${({ theme }) => theme.colors.grayBlue};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin: 0 0 14px;
  }
`

export const Description = styled(Typography).attrs({
  type: 'span',
  variant: TextStyles['Large Paragraph Text'],
})`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.grayBlue};
`

export const EventButton = styled(Button)`
  margin-top: auto;
`

export const ThumbnailOverlayLink = styled(DynamicLink)`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const StyledButton = styled(Button)`
  position: relative;
  z-index: 2;
  min-width: initial;
`
