import styled, { css } from 'styled-components'
import Button from '@/components/Button'
import COLOR_PALETTE from '@/constants/colorPalette.constants'
import { SECTION_WRAPPER_PADDING_DESKTOP } from '@/components/SectionWrapper/SectionWrapper.styles'
import {
  AboveTheFoldWrapper,
  ButtonsWrapper,
  ProductName,
  SubCopy,
} from '../../AboveTheFold.styles'
import { Header, AnimatedWord } from '../HeaderText/HeaderText.styles'
import Image from 'next/image'

const {
  orange,
  blue,
  teal,
  purple,
} = COLOR_PALETTE

const heroImageStyling = css`
  display: block;
  margin-top: 32px;
  margin-bottom: -32px;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: -${SECTION_WRAPPER_PADDING_DESKTOP}px;
    margin-bottom: -${SECTION_WRAPPER_PADDING_DESKTOP}px;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const CustomAboveTheFoldWrapper = styled(AboveTheFoldWrapper)`
  ${ProductName} {
    text-align: left;
  }

  ${Header} {
    margin-bottom: 16px;
    text-align: left;

    ${AnimatedWord}, span[style^='color'] {
      background: linear-gradient(90deg, ${orange[3]} 10.18%, ${purple[3]} 28.67%, ${blue[5]} 51.3%, ${teal[3]} 69.41%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent !important;
    }
  }

  ${SubCopy} {
    text-align: left;
  }

  ${ButtonsWrapper} {
    margin-top: 24px;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      margin-top: 32px;
    }

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }

  .hero-image, .hero-image-with-animation {
    ${heroImageStyling}
  }
`

export const HomepagePrimaryButton = styled(Button)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  :not([disabled]):hover {
    background-color: ${({ theme }) => theme.colors.orangeHover} !important;
  }
`

export const HomepageSecondaryButton = styled(Button).attrs({
  variant: 'secondary',
})`
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  :not([disabled]):hover {
    border-color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.white05} !important;
  }
`

export const HeroImageContainer = styled.div`
  position: relative;
  margin-top: 32px;
  margin-bottom: -32px;
  margin-left: auto;
  margin-right: auto;
  width: 327px;
  height: 237px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: -${SECTION_WRAPPER_PADDING_DESKTOP}px;
    margin-bottom: -${SECTION_WRAPPER_PADDING_DESKTOP}px;
    position: absolute;
    right: 0;
    top: 0;
    width: 612px;
    height: 444px;
  }
`

export const HomepageHeroImageImg = styled(Image)``

export const HomepageHeroImageImgWithAnimation = styled(HomepageHeroImageImg)`
  opacity: 0;
`
