import styled from 'styled-components'
import { FilledButtonAsLink } from '../Button.styles'

export const ButtonLink = styled(FilledButtonAsLink)`
  color: ${({ theme }) => theme.colors.brightBlue} !important;
  padding: 0;
  height: unset !important;
  border-radius: unset;
  word-break: break-all;
  
  justify-content: flex-start;
  width: fit-content;
  min-width: auto;

  :not([disabled]):hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.blue} !important;
  }

  :not([disabled]):visited {
    color: ${({ theme }) => theme.colors.blue} !important;
  }

  & > span > svg,
  & > svg {
    fill: ${({ theme }) => theme.colors.brightBlue};

    & path {
      fill: ${({ theme }) => theme.colors.brightBlue};
    }
  }

  :not([disabled]):hover {
    & > span > svg,
    & > svg {
      fill: ${({ theme }) => theme.colors.blue};

      & path {
        fill: ${({ theme }) => theme.colors.blue};
      }
    }
  }
`

export const Button = styled(ButtonLink).attrs({
  as: 'button',
})``

export default { Button, ButtonLink }
