import { lightTheme } from '@/constants'
import React from 'react'
import { useTheme } from 'styled-components'

interface HighlightedSpanProps {
  text: string
}

const HighlightedSpan: React.FC<HighlightedSpanProps> = ({ text }) => {
  const { sectionTheme, pageTheme } = useTheme()
  const highlightedColor: string =
    pageTheme?.main ||
    sectionTheme?.highlightedText ||
    lightTheme.highlightedText

  const hightlightedString = text.match(/\{\{([^)]+)\}\}/)?.[1]
  if (!hightlightedString) {
    return <span>{text}</span>
  }

  const highlightedString = (
    <span style={{ color: highlightedColor }}>{hightlightedString}</span>
  )

  const prefix = text.split('{{')[0]
  const suffix = text.split('}}')[1]

  return (
    <span>
      {prefix}
      {highlightedString}
      {suffix}
    </span>
  )
}

export default HighlightedSpan
