import styled from 'styled-components'
import { TextStyles, defaultSectionTheme } from '@/constants'
import { ImageSize } from '@/interfaces/contentItem'
import Typography from '@/components/Typography'
import Image from 'next/image'

export const StatisticsContainer = styled.div`
  width: calc(50% - 35px);

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 210px;
  }
`

export const StatisticImage = styled(Image)`
  object-fit: contain;
  object-position: top left;
`

export const AssestWrapper = styled.div<{ imageSize?: ImageSize }>`
  ${StatisticImage}, > .dotlottie-container {
    max-width: 100%;
    height: ${({ imageSize }) => imageSize === ImageSize.Large ? '70px' : '50px'};
    margin-bottom: 10px;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      height: ${({ imageSize }) => imageSize === ImageSize.Large ? '100px' : '70px'};
      margin-bottom: 20px;
    }
  }

  > .dotlottie-container {
    display: inline-block;
  }
`

export const StatisticTitle = styled(Typography).attrs({
  variant: TextStyles['Large Display Font'],
})`
  color: ${({ theme }) =>
    theme.sectionTheme?.highlightedText || defaultSectionTheme.highlightedText};
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StatisticDescription = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  color: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text};
`
