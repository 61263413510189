import styled from 'styled-components'
import { TextStyles, defaultSectionTheme } from '@/constants'
import Typography from '../Typography'
import { EventButton } from '../Thumbnail/Thumbnail.styles'
import { styledText } from '../Typography/Typography.styles'

export const Wrapper = styled.div<{ buttonCopy?: string }>`
  background: ${({ theme }) => theme.sectionTheme?.background};
  width: 315px;
  margin: 0 auto;
  padding-bottom: ${({ buttonCopy }) => buttonCopy ? '30px' : '10px'};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-bottom: 0px;
    width: 100%;
  }
`

export const ItemWrapper = styled.div<{ alignment?: 'Left' | 'Right', isStandalone?: boolean }>`
  display: ${({ isStandalone }) => isStandalone ? 'none' : 'flex'};
  flex-direction: column;
  color: ${({ theme }) => theme.sectionTheme?.text};
  position: relative;
  word-break: break-word;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
    flex-direction: row;
    justify-content: ${({ alignment }) => alignment === 'Right' ? 'flex-end' : 'space-between'};
  }
`

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 60px;
    max-width: 600px;
  }
`

export const SubTitle = styled(Title)`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 20px;
  }
`

export const SubCopy = styled.div`
  ${styledText[TextStyles['Large Paragraph Text']]}
  margin-bottom: 20px;
`

export const ProductMockup = styled.img``

// Minus 15px to not align with the 10px-curved border of outer orange line at ProductFeaturesList 
const horizontalLineTop = 76 - 15
const useCaseInnerContentPadding = 34

export const VerticalConnectorLine = styled.div<{ imageHeight: number, alignment?: 'Left' | 'Right' }>`
  position: absolute;
  bottom: -${horizontalLineTop}px;
  height: ${({ imageHeight }) => imageHeight && `calc(100% - ${imageHeight - horizontalLineTop}px)`};
  background: ${({ theme }) => theme.colors.orange};
  width: 1px;
  display: none;

  ${({ alignment }) => alignment === 'Left' ? `
    left: calc(290px - ${useCaseInnerContentPadding}px);
  ` : `
    right: calc(290px - ${useCaseInnerContentPadding}px);
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
  }
`

export const ProductMockupWrapper = styled.div<{ alignment?: 'Left' | 'Right' }>`
  max-width: 100%;
  height: fit-content;
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 580px;
    min-width: 580px;
    margin-bottom: 0;
    ${({ alignment }) => alignment === 'Left' ? `
      order: 1;
      margin-right: 39.5px;
    ` : `
      order: 2;
      margin-left: 39.5px;
    `}
  }

  ${ProductMockup}, > .dotlottie-container {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      width: 100%;
    }
  }
`

export const SubPoints = styled.div<{ alignment?: 'Left' | 'Right', subTitle?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  order: ${p => p.alignment === 'Left' ? 2 : 1};
  width: ${p => p.alignment === 'Left' ? '100%' : 'auto'};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${({ subTitle }) => !subTitle && 'padding-top: 15px'};
  }
`

export const SubPoint = styled.div<{ isStandalone?: boolean, subTitle?: string  }>`
  display: flex;
  flex-direction: column;
  align-items: top;
  margin-bottom: 20px;
  padding-left: ${({ isStandalone, subTitle }) => isStandalone ? '0' : (subTitle ? '30px' : '40px')};
  overflow: hidden;
  word-break: break-word;

  &::before {
    content: '';
    border-style: solid;
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent ${({ theme }) => theme.sectionTheme?.text};
    position: absolute;
    left: ${({ subTitle }) => subTitle ? '10px' : '20px'};
    transform: translateY(25%) translateX(-100%);
    display: ${({ isStandalone }) => isStandalone ? 'none' : 'block'};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: ${({ subTitle }) => subTitle ? '10px' : '40px'};
    padding-left: ${({ subTitle }) => subTitle ? '30px' : '40px'};;

    &::before {
      content: '';
      border-width: 10px 0 10px 10px;
      display: block;
    }
  }
`

export const SubPointTitle = styled.div<{ isStandalone?: boolean }>`
  ${styledText[TextStyles['Heading 3']]}
  display: ${({ isStandalone }) => isStandalone ? 'none' : 'block'};
  margin-bottom: 5px;
  
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 2']]}
    display: block;
    margin-bottom: 10px;
  }
`

export const SubPointDesc = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})<{ isStandalone?: boolean }>`
  color: ${({ theme }) => theme.sectionTheme?.subText || defaultSectionTheme.subText};
`

export const Button = styled(EventButton)`
  margin-top: 0px;
  min-width: initial;
  color:  ${({ theme }) => theme.colors?.orange} !important;

  & > svg {
    & path {
      fill: ${({ theme }) => theme.colors?.orange} !important;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 20px;
  }
`
