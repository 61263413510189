import styled, { css } from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography/Typography'
import { styledText } from '@/components/Typography/Typography.styles'
import { FilledButtonAsLink } from '@/components/Button/Button.styles'

export const Image = styled.img`
  width: 100%;
  height: 180px;
  display: block;
  object-fit: cover;
  flex: 0 0 auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: 400px;
  }
`

export const TitleHolder = styled.div<{ minHeight: number | undefined }>`
  height: auto;
  margin: 0 0 10px 0;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    height: ${props => props.minHeight ? `${props.minHeight}px` : 'auto'};
    margin: 0 0 20px 0;
  }
`

export const Title = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Heading 3'],
})`
  color: ${({ theme }) => theme.colors.darkBlue};
  min-height: 1px;
  word-break: break-word;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 2']]}
  }
`

export const SubTitle = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  color: ${({ theme }) => theme.colors.darkBlue};
`

export const Description = styled(Typography).attrs({
  variant: TextStyles['Small Paragraph Text'],
})`
  flex: auto;
  word-break: break-word;
  color: ${({ theme }) => theme.colors.darkGray};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Large Paragraph Text']]}
  }
`

export const LinkContainer = styled.div`
  display: block;
  margin-top: 10px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 20px;
  }

  > ${FilledButtonAsLink} {
    padding: 0;
  }
`

export const Container = styled.div<{ hasSubTitle?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 258px;
  max-width: 540px;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    border-radius: 8px;
    width: 540px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    width: 100%;
  }

  //--- In case has sub-title
  ${(props) => props.hasSubTitle && css`
    ${Image} {
      height: 276px;
    }

    ${Title} {
      ${styledText[TextStyles['Heading 2']]}
    }

    ${({ theme }) => theme.mediaBreakpointUp.md} {

      ${Image} {
        height: 400px;
      }
    }
  `}
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 100%;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: 50px;
  }
`
