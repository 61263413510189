import { useMemo } from 'react'
import SlideTrack from './SlideTrack'
import { Slider } from './styles'
import useSettingByBreakpoint from './useSettingByBreakpoint'
import { SliderProps } from './models'
import { useWindowSize } from '@/hooks'

const AutoplayInfiniteSlider = <T extends object>({
  rtl = false,
  speed = 3000,
  delay = 0,
  keyName,
  items,
  gap,
  itemSize,
  renderItem,
  responsive,
}: SliderProps<T>) => {
  if (!items?.length) return null

  const { width } = useWindowSize()

  const initSetting = {
    gap, itemSize, renderItem 
  }
  const setting = useSettingByBreakpoint(initSetting, responsive)

  const itemSizeIncludeGap = setting.itemSize + (setting.gap ?? 0)

  const slidesPerView = useMemo(() => {
    const maxItemShow = width / itemSizeIncludeGap

    if (items.length > maxItemShow) {
      return maxItemShow
    }

    if (items.length > 1) {
      return items.length - 1
    }

    return items.length
  }, [width, itemSizeIncludeGap, items.length])

  return (
    <Slider $width={slidesPerView * itemSizeIncludeGap}>
      <SlideTrack
        rtl={rtl}
        gap={setting.gap}
        speed={speed}
        delay={delay}
        items={items}
        keyName={keyName}
        slidesPerView={slidesPerView}
        itemSizeIncludeGap={itemSizeIncludeGap}
        renderItem={setting.renderItem}
      />
    </Slider>
  )
}

export default AutoplayInfiniteSlider
