import styled from 'styled-components'
import { Button } from '..'

const LoadMoreButton = styled(Button).attrs({ variant: 'secondary' })`
  margin-top: 30px;
  padding: 0 16px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 60px;
  }
`

export default LoadMoreButton
