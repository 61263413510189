import get from 'lodash.get'
import { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { SlideTrackWrapper } from './styles'

interface SlideTrackProps<T> {
  rtl: boolean
  gap?: number
  speed: number
  delay: number
  items: T[]
  keyName: string
  slidesPerView: number
  itemSizeIncludeGap: number
  renderItem: (item: T) => any
}

const SlideTrack = <T extends object>({
  rtl,
  gap,
  speed,
  delay,
  items,
  keyName,
  slidesPerView,
  itemSizeIncludeGap,
  renderItem,
}: SlideTrackProps<T>) => {
  const [play, setPlay] = useState(false)
  const [inView, setInView] = useState({ start: 0, end: 0 })

  const initEndIndex = Math.floor(slidesPerView) + 1

  // Step 1: Determine the initital slides which are rendered "in" the "view"
  useEffect(() => {
    setInView({ start: 0, end: initEndIndex })
  }, [initEndIndex])

  // Step 2: Trigger "play" animation based on "delay" time props
  useEffect(() => {
    const timeout = setTimeout(() => {
      setPlay(true)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [delay])

  // Step 3: Play animation after triggered by action "play" based on "speed" props
  useEffect(() => {
    if (speed <= 0 || !play) return

    const interval = setInterval(() => {
      setInView((prevInView) => ({
        start: prevInView.start + 1,
        end: prevInView.end + 1,
      }))
    }, speed)

    return () => {
      clearInterval(interval)
    }
  }, [speed, play])

  // Step 4: Restart the animation to prepare for the 2nd loop running
  useEffect(() => {
    if (inView.start === items.length) {
      setInView({ start: 0, end: initEndIndex })
    }
  }, [initEndIndex, items.length, inView])

  const spring = useSpring({
    from: { translateX: 0 },
    to: { translateX: rtl ? itemSizeIncludeGap : -itemSizeIncludeGap },
    config: { duration: speed },
    reset: true,
  })

  return (
    <SlideTrackWrapper rtl={rtl} gap={gap}>
      {[...items, ...items].map((item, i) => {
        if (i >= inView.start && i <= inView.end) {
          return (
            <animated.div
              key={get(item, keyName)}
              style={speed <= 0 || !play ? undefined : spring}
            >
              {renderItem(item)}
            </animated.div>
          )
        }

        return null
      })}
    </SlideTrackWrapper>
  )
}

export default SlideTrack
