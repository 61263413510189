import styled from 'styled-components'

export const Slider = styled.div<{ $width?: number }>(({ $width }) => ({
  ...($width ? { width: $width } : {}),
  position: 'relative',
  overflow: 'hidden',
  margin: '0 auto',

  ['::before']: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'block',
    zIndex: 1,
  },
}))

export const SlideTrackWrapper = styled.div<{ rtl: boolean, gap?: number }>(
  ({ rtl, gap }) => ({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    ...(rtl ? { direction: 'rtl', float: 'right' } : {}),

    ['> *']: {
      display: 'inline-block',
      ...(gap ? (rtl ? { marginLeft: gap } : { marginRight: gap }) : {}),
    },
  })
)
