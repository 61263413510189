// Component
import {
  Wrapper,
  Title,
  SliderWrapper,
  StyledImage,
  SecondaryButton,
} from './Animated.styles'
import {
  Container,
  LocalThemeProvider 
} from '@/components'

import AutoplayInfiniteSlider from './AutoplayInfiniteSlider'

// Type
import { Asset } from '@/interfaces/common'
import { CarrierProps } from '@/interfaces/carrier'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'

// Util
import { getSectionTheme } from '@/utils'

// Hook
import { useHighlightString } from '@/hooks'
import { SectionSeparator } from '@/components/WrapperContentItems/components'

const Animated: React.FC<CarrierProps> = (props) => {
  const {
    title,
    firstCarrierList,
    secondCarrierList,
    buttonText,
    buttonLink,
    buttonEventLabel,
    openInNewTab,
    withSeparator,
  } = props
  const ITEM_WIDTH_ON_DESKTOP = 128
  const ITEM_WIDTH_ON_MOBILE = 75

  const firstCarriers = firstCarrierList?.filter((item: any) => item)
  const secondCarriers = secondCarrierList?.filter((item: any) => item)

  const renderSlide = (slideSize: number) => ({ url, description }: Asset) => (
    <StyledImage
      src={url}
      alt={description || 'Carrier logo'}
      width={slideSize}
      height={slideSize}
      placeholder="blur"
      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNcVQ8AAdkBKwhkHIIAAAAASUVORK5CYII="
    />
  )

  const renderAutoplayInfiniteSlider = (items?: Asset[], rtl = false) => (
    <AutoplayInfiniteSlider
      rtl={rtl}
      delay={3000}
      items={items}
      keyName="sys.id"
      gap={24}
      itemSize={ITEM_WIDTH_ON_DESKTOP}
      renderItem={renderSlide(ITEM_WIDTH_ON_DESKTOP)}
      responsive={{
        md: {
          gap: 7,
          itemSize: ITEM_WIDTH_ON_MOBILE,
          renderItem: renderSlide(ITEM_WIDTH_ON_MOBILE),
        },
      }}
    />
  )

  return (
    <LocalThemeProvider
      theme={{ sectionTheme: getSectionTheme(SectionThemeKey.Light) }}
    >
      <Wrapper>
        <Container>
          {title && <Title>{useHighlightString(title)}</Title>}
        </Container>
        <SliderWrapper>
          {renderAutoplayInfiniteSlider(firstCarriers, true)}
          {renderAutoplayInfiniteSlider(secondCarriers)}
        </SliderWrapper>
        <Container>
          {buttonLink && buttonText && (
            <SecondaryButton
              href={buttonLink}
              eventLabel={buttonEventLabel}
              openInNewTab={openInNewTab}
            >
              {buttonText}
            </SecondaryButton>
          )}
        </Container>
      </Wrapper>
      {withSeparator && <SectionSeparator />}
    </LocalThemeProvider>
  )
}

export default Animated
