const bannerFields = (isPreviewMode: boolean): string => {
  return `
    fragment bannerFields on Banner {
      sys {
        id
      }
      uiTemplate
      icon {
        url(transform: {
          width: 102,
          resizeStrategy: FIT,
        })
        description
        contentType
      }
      productName
      header
      productHeader {
        title
        description
        image {
          url
          description
          contentType
        }
      }
      subCopy
      backgroundImage {
        url(transform: {
          width: 743,
          resizeStrategy: FIT,
          quality: 100,
        })
        description
        contentType
      }
      heroImages: heroImagesCollection(preview: ${isPreviewMode}, limit: 13) {
        items {
          ... on ContentItem {
            title
            image {
              url(transform: {
                width: 960,
                resizeStrategy: FIT,
                quality: 100,
              })
              description
              contentType
            }
          }
        }
      }
      primaryButtonText
      primaryButtonLink
      primaryButtonEventLabel
      primaryButtonOpenInNewTab
      secondaryButtonText
      secondaryButtonLink
      secondaryButtonEventLabel
      secondaryButtonOpenInNewTab
      alignment
      theme
      backgroundColor
    }
  `
}

const contentItemFields = (): string => {
  return `
    fragment contentItemFields on ContentItem {
      sys {
        id
      }
      uiTemplate
      title
      subTitle
      description
      seoDescription {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              contentType
              url(transform: {
                width: 800,
                resizeStrategy: FIT,
              })
              height
              width
              title
              description
            }
          }
          entries {
            block {
              sys {
                id
              }
              __typename
              ... on Table {
                table 
              }
              ... on Quote {
                quote
                author
              }
            }
          }
        }
      }
      image {
        url(transform: {
          width: 100,
          resizeStrategy: FIT,
        })
        description
        contentType
      }
      imageSize
      buttonText
      buttonLink
      buttonEventLabel
      openInNewTab
      backgroundColor
    }
  `
}

const customerCardFields = (): string => {
  return `
    fragment customerCardFields on CustomerCard {
      sys {
        id
      }
      imageAlignment
      image {
        url(transform: {
          width: 700,
          resizeStrategy: FIT,
          format: PNG
        })
        description
        contentType
      }
      customerLogo {
        url
        description
      }
      customerName
      customerImage {
        url(transform: {
          width: 78,
          resizeStrategy: FIT,
          format: PNG
        })
        description
      }
      customerTitle
      testimonialQuote
      buttonText
      buttonLink
      buttonEventLabel
      openInNewTab
    }
  `
}

const productUseCaseFields = (): string => {
  return `
    fragment productUseCaseFields on ProductUseCase {
      sys {
        id
      }
      title
      icon {
        title
        url(transform: {
          width: 48,
          height: 48,
          resizeStrategy: FIT,
        })
        contentType
      }
      subTitle
      subCopy
      theme
      alignment
      subPoints
      propositionList
      productMockup {
        description
        url(transform: {
          width: 870,
          resizeStrategy: FIT,
        })
        contentType
      }
      buttonCopy
      buttonLink
      buttonEventLabel
      openInNewTab
    } 
  `
}

const wrapperContentItemsFields = (isPreviewMode: boolean): string => {
  return `
    fragment wrapperContentItemsFields on WrapperContentItems {
      uiTemplate
      primaryTitle
      secondaryTitle
      subTitle {
        json
      }
      theme
      withSeparator
      showConnectorLines
      showFirstConnectorLine
      showLastConnectorLine
      buttonText
      buttonLink
      buttonEventLabel
      openInNewTab
      itemList: itemListCollection(preview: ${isPreviewMode}, limit: 10) {
        items {
          ... on ContentItem {
            ...contentItemFields
          }
          ... on CustomerCard {
          ...customerCardFields
          }
          ... on ProductUseCase {
            __typename
            ...productUseCaseFields
          }
          ... on Banner {
            __typename
            ...bannerFields
          }
        }
      }
    }
  `
}

const carriersFields = (isPreviewMode: boolean): string => {
  return `
    fragment carriersFields on Carriers {
      sys {
        id
      }
      uiTemplate
      smallTitle
      title
      firstCarrierList: firstCarrierListCollection(preview: ${isPreviewMode}, limit: 30) {
        items {
          sys { 
            id
          }
          url
          description
          contentType
        }
      }
      secondCarrierList: secondCarrierListCollection(preview: ${isPreviewMode}, limit: 30) {
        items {
          sys { 
            id
          }
          url
          description
          contentType
        }
      }
      buttonText
      buttonLink
      buttonEventLabel
      openInNewTab
      withSeparator
    }
  `
}

const leadsWrapperFields = (
  isPreviewMode: boolean,
  productImagesSize: number
): string => {
  return `
    fragment leadsWrapperFields on LeadsWrapper {
      sys {
        id
      }
      uiTemplate
      title
      leadSubCopy {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              contentType
              url(transform: {
                width: 505,
                resizeStrategy: FIT,
              })
              height
              width
              title
              description
            }
          }
          entries {
            block {
              sys {
                id
              }
              __typename
              ... on Table {
                table 
              }
            }
          }
        }
      }
      leadsForm {
        ... on Form {
          hubspotFormId
          title
          eventLabel
          buttonText
          buttonEventLabel
          linkedInAdsId
          zapierHookId
          thankYouTitle
          thankYouMessage {
            json
          }
          formFieldList: formFieldListCollection(preview: ${isPreviewMode}, limit: 10) {
            items {
              ... on FormField {
                name
                label {
                  json
                }
                placeholder
                isMandatory
                type
                isNonCompanyEmailDomainBlocked
                width
                mandatoryCheckErrorMessage
                emailDomainBlockedCheckErrorMessage
                customErrorMessage
              }
            }
          }
        }
      }
      backgroundImage {
        url(transform: {
          width: 1440,
          resizeStrategy: FIT,
          format: PNG
        })
        description
      }
      buttonText
      buttonLink
      buttonEventLabel
      openInNewTab
      highlightedSubCopy
      productImages: productImagesCollection(preview: ${isPreviewMode}, limit: 3) {
        items {
          sys {
            id
          }
          url(transform: {
            width: ${productImagesSize},
            resizeStrategy: FIT,
            format: PNG
          })
          description
        }
      }
      customerLogos: customerLogosCollection(preview: ${isPreviewMode}, limit: 6) {
        items {
          sys {
            id
          }
          url(transform: {
            height: 30,
            resizeStrategy: FIT,
            format: PNG
          })
          description
        }
      }
      tinyCopy
      alignment
      theme
    }
  `
}

const carrierListingFields = (): string => {
  return `
    fragment carrierListingFields on CarrierListing {
      primaryTitle
      secondaryTitle
      type
      theme
      withSeparator
      allCountriesText
      searchCountryInputPlaceholder
      searchInputPlaceholder
      singularCarriersFoundText
      pluralCarriersFoundText
      paginationText
      notFoundMessage {
        json
      }
    }
  `
}

const contentFeedFields = (): string => {
  return `
    fragment contentFeedFields on ContentFeed {
      primaryTitle
      secondaryTitle
      tag {
        sys {
          id
        }
        pageSlug
        title
        type
      }
      withSeparator
      showDate
      showButton
    }
  `
}

const eventsSectionFields = (): string => {
  return `
    fragment eventsSectionFields on EventsSection {
      title
      type
      buttonText
      withSeparator
      showDate
      showLocation
    }
  `
}

const seoFields = (): string => {
  return `
    fragment seoFields on Seo {
      sys {
        id
      }
      title
      description
      ogType
      ogUrl
      ogImage {
        url
        description
      }
      ogTitle
      ogDescription
      robots
    } 
  `
}

const wrapperFields = (isPreviewMode: boolean): string => {
  return `
    fragment wrapperFields on Wrapper {
      title
      buttonText
      buttonLink
      openInNewTab
      buttonEventLabel
      itemList: itemListCollection(preview: ${isPreviewMode}, limit: 6) {
        items {
          ... on ContentItem {
            ...contentItemFields
          }
        }
      }
    }
  `
}

export {
  seoFields,
  contentFeedFields,
  bannerFields,
  leadsWrapperFields,
  eventsSectionFields,
  carriersFields,
  contentItemFields,
  customerCardFields,
  productUseCaseFields,
  carrierListingFields,
  wrapperContentItemsFields,
  wrapperFields,
}
