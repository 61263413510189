import {
  useEffect, useMemo, useState 
} from 'react'
import { useMediaQuery } from '@/hooks'
import { BreakpointNames } from '@/interfaces/responsive'
import { breakpoints as BREAKPOINTS } from '@/constants/theme/breakpoint'
import { ResponsiveSetting, SliderProps } from './models'

const useSettingByBreakpoint = <T>(
  initSetting: ResponsiveSetting<T>,
  responsive: SliderProps<T>['responsive']
) => {
  const [setting, setSetting] = useState(initSetting)

  const breakpoints = responsive ? Object.entries(responsive) : []

  const mediaQueries = breakpoints.map(
    ([breakpointName]) =>
      `(max-width: ${BREAKPOINTS[breakpointName as BreakpointNames]})`
  )

  const matches = useMediaQuery(mediaQueries)

  const breakpointFound = useMemo(
    () => breakpoints.find((_, index) => matches[index]),
    [breakpoints, matches]
  )

  useEffect(() => {
    if (!breakpointFound) return

    setSetting((prev) => {
      let _setting = { ...prev }

      const [, breakpointSetting] = breakpointFound

      Object.keys(_setting).forEach((key) => {
        const typedKey = key as keyof ResponsiveSetting<T>

        if (breakpointSetting[typedKey]) {
          _setting = { ..._setting, [typedKey]: breakpointSetting[typedKey] }
        }
      })

      return _setting
    })
  }, [])

  return setting
}

export default useSettingByBreakpoint
